import Vue from 'vue'
import moment from 'moment'

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('formatCurrency', function (value) {
  if (value >=0 ) {
    return '$' + value;
  }
})

Vue.filter('formatPercentage', function (value) {
  if (value) {
    return value + '%';
  }
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
})

Vue.filter('formatLongDate', function (value) {
  if (value) {
    return moment(String(value)).format('ddd, DD MMM yy')
  }
})

Vue.filter('formatLongDateAgo', function (value) {
  if (value) {
    return moment(String(value)).startOf('day').fromNow()
  }
})

Vue.filter('formatLongDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('ddd, DD MMM yy HH:mm')
  }
})
