import * as Msal from "@azure/msal-browser";
import { BaseConfig } from "../store/api/config";

export default class AuthService {
  constructor() {
    this.app = new Msal.PublicClientApplication({
      auth: {
        clientId: `${BaseConfig.AppClientID}`,
        authority: `${BaseConfig.AppLoginAuthority}`,
        // knownAuthorities: [`${BaseConfig.AppLoginAuthority}`],
        // validateAuthority: false,
        // navigateToLoginRequestUrl: true,
        // redirectUri: `${BaseConfig.AppUrl}login`,
        postLogoutRedirectUri: `https://smartek.com/`,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
        secureCookies: true
      }
    });
  }

  generateTokenRequest(scope, silent) {
    let currentAccount = this.getUser();
    let tokenRequest = {
      scopes: [`${BaseConfig.BHSCOPE}`],
      account: currentAccount,
      forceRefresh: false
    };

    if (silent) {
      tokenRequest.forceRefresh = true;
      tokenRequest.loginHint = currentAccount.username;
    }

    return tokenRequest;
  }

  async getSilentToken(scope) {
    let accessTokenRequest = this.generateTokenRequest(scope, false);
    let accessToken = "";
    try {
      const tokenResponse = await this.app.acquireTokenSilent(accessTokenRequest);
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      console.log("Debug: error in getting silent token", error);
      accessTokenRequest =  this.generateTokenRequest(scope, true);

      try {
        console.log("Debug: getting new token via redirect");
        tokenResponse = await this.app.acquireTokenPopup(accessTokenRequest);
        accessToken = tokenResponse.accessToken;
      } catch (errorPopup) {
        console.log(`Error acquiring the popup: ${errorPopup}`);
        this.$store.dispatch("logout");
        return undefined;
      }
    }
    return accessToken;
  }

  logout() {
    this.app.logoutRedirect({});
  }

  getUser() {
    let allAccounts = this.app.getAllAccounts();
    return allAccounts[0];
  }
}
