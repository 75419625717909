<template>
    <div id="iframe-wrapper">
        <iframe :src="iframe.src" :style="iframe.style" :height="iframe.style.height" :width="iframe.style.width" ></iframe>
    </div>
</template>

<script>

export default {
    name: "vx-pdf",
    data() {
        return {
            loaded: false,
            iframe: {
                src: '',
                style: {
                    position: 'absolute',
                    width: '80vw',
                    height: '87vh',
                    left: '10px'
                },
                wrapperStyle: null,
                height: '87vh',
                width: '600px',
                position: 'absolute'
            }
        }
    },
    props: ['src'],
    mounted() {
        this.iframe.style = {
            position: 'absolute',
            width: '97%',
            height: '87vh'
        }
        this.iframe.src = this.src
        this.loaded = true
    }
}
</script>
