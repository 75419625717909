import Vue from "vue";
import Router from "vue-router";
import Store from "./store/store";
import { mapActions } from "vuex";
import { profileData } from "./store/api/profile";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  methods: {
    ...mapActions(["login"])
  },
  routes: [
    {
      path: "/",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "/admin/home",
          name: "adminhome",
          component: () => import("@/views/Home.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/client-department",
          name: "Client Department",
          component: () => import("@/views/ClientDepartment/DepartmentPage.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/clearance-type",
          name: "Clearance Type",
          component: () => import("@/views/ClearanceType/ClearancePage.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/user",
          name: "user",
          component: () => import("@/views/User/UserPage.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/admin/Customers",
          name: "customers",
          component: () => import("@/views/Customer/CustomerPage.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/admin/Customers/add",
          name: "customers",
          component: () => import("@/views/Customer/AddCustomer.vue"),
          meta: {
            parent: "customer",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/admin/Customers/edit/:id?",
          name: "customers",
          component: () => import("@/views/Customer/AddCustomer.vue"),
          meta: {
            parent: "customer",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/admin/Customers/detail/:id?",
          name: "customers",
          component: () => import("@/views/Customer/CustomerInfo.vue"),
          meta: {
            parent: "customer",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/admin/Customers/edit/:id?",
          name: "customers",
          component: () => import("@/views/Customer/CustomerProfile.vue"),
          meta: {
            parent: "customer",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        }, {
          path: "/Reports/RequestReceived",
          name: "RequestReceived",
          component: () => import("@/views/Reports/RequestReceived.vue"),
          meta: {
            parent: "",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        }, {
          path: "/Reports/NonCompletedJobs",
          name: "NonCompletedJobs",
          component: () => import("@/views/Reports/NonCompletedJobs.vue"),
          meta: {
            parent: "",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        },
        {
          path: "/Reports/CompletedJobs",
          name: "CompletedJobs",
          component: () => import("@/views/Reports/CompletedJobs.vue"),
          meta: {
            parent: "",
            requireAuth: true,
            userType: ["Administrator","CaseOfficer"]
          }
        }
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "/unauthorized",
          name: "unauthorized",
          component: () => import("@/views/common/unauthorized.vue")
        },
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/common/PreLogin.vue")
        },
        {
          path: "/completeLogin",
          name: "completeLogin",
          component: () => import("@/views/common/completeLogin.vue")
        },
        {
          path: "/logout",
          name: "logout",
          component: () => import("@/views/common/Logout.vue")
        }
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "/*",
          name: "unauthorized",
          component: () => import("@/views/common/unauthorized.vue")
        },
      ]
    }
  ]
});

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  Store.dispatch("setGlobalMessage", "");
});

router.beforeEach(async (to, from, next) => {
  Store.dispatch("setGlobalMessage", "");
  if (to.meta.requireAuth) {
    if (!Store.state.isAuthenticated) {
      return router.push("/login");
    }

    if (to.meta.userType) {
      let user = await profileData.SearchMyProfile();
      if (!user || user.azureObjectId === '00000000-0000-0000-0000-000000000000') {
        router.push("/completeprofile");
        return;
      }

      if (!to.meta.userType.includes(user.userType)) {
        router.push("/unauthorised");
        Store.dispatch(
          "setGlobalMessage",
          "You are not allowed to perform this action."
        );
        return next(false);
      }
    }
  }

  return next();
});

export default router;
