import { axiosInstance } from "../../axios";

const GETMyProfile = `/api/User/Get`;
const POSTSaveProfile = "/api/MyProfile/SaveProfile";
const POSTSaveProfileByUser = "/api/MyProfile/SaveProfileByUser";
const GETSearchContactAddress = `/api/ContactAddress/SearchContactAddress`;
const GETSearchContactAddressByUser = `/api/ContactAddress/SearchContactAddressByUser/`;
const POSTSaveContactAddress = "/api/ContactAddress/SaveAddress";
const POSTSaveContactAddressByUser = "/api/ContactAddress/SaveAddressByUser";
const GETSearchContactMailingAddress = `/api/ContactAddress/SearchContactMailingAddress`;
const GETSearchContactMailingAddressByUser = `/api/ContactAddress/SearchContactMailingAddressByUser/`;
const POSTSaveContactMailingAddress = "/api/ContactAddress/SaveMailingAddress";
const POSTSaveContactMailingAddressByUser = "/api/ContactAddress/SaveMailingAddressByUser";
const GetDrivingLicense = `/api/IdentityDocument/GetDrivingLicense`;
const GetDrivingLicenseByUser = `/api/IdentityDocument/GetDrivingLicenseByUser/`;
const GETValidateProfile = `/api/MyProfile/ValidateMyProfile`;

const SearchMyProfile = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETMyProfile);
    return response.data;
  } catch (error) {
    return null;
  }
};

const searchProfileById = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETProfileById + "/" + id);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};


const SearchIdentityDocument = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GetDrivingLicense);
    return response.data;
  } catch (error) {
    return null;
  }
};

const SearchIdentityDocumentByUser = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GetDrivingLicenseByUser + id);
    return response.data;
  } catch (error) {
    return null;
  }
};

const saveProfile = async function(profile) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSaveProfile, profile);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const saveProfileByUser = async function(profile) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSaveProfileByUser, profile);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const SearchContactAddress = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(
      GETSearchContactAddress
    );
    let data = parseList(response);
    return data;
  } catch (error) {
    return null;
  }
};


const SearchContactAddressByUser = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchContactAddressByUser}${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const ValidateMyProfile = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(
      GETValidateProfile
    );
    let data = parseList(response);
    return data;
  } catch (error) {
    return parseError(error);
  }
};

const SaveContactAddress = async function(profile) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSaveContactAddress, profile);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const SaveContactAddressByUser = async function(profile) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSaveContactAddressByUser, profile);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const SearchContactMailingAddress = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETSearchContactMailingAddress);
    return response.data;
  } catch (error) {
    return null;
  }
};

const SearchContactMailingAddressByUser = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchContactMailingAddressByUser}${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const SaveContactMailingAddress = async function(profile) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSaveContactMailingAddress, profile);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const SaveContactMailingAddressByUser = async function(profile) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSaveContactMailingAddressByUser, profile);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const parseList = response => {
  if (response.status === 204) throw Error("Please Select Company");
  if (response.status !== 200) throw Error(response.message);

  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

const parseError = error => {
  if (error.response.data.Errors){
    let stringMessage = error.response.data.Errors.join(" <br/> ");
    error.response.data.Message += "<br/>" + stringMessage;
    return error.response.data;
  } else{
    return error.response.data;
  }
};

export const profileData = {
  SearchMyProfile,
  saveProfile,
  saveProfileByUser,
  SearchContactAddress,
  SearchContactAddressByUser,
  SearchContactMailingAddress,
  SearchContactMailingAddressByUser,
  SaveContactAddress,
  SaveContactAddressByUser,
  SaveContactMailingAddress,
  SaveContactMailingAddressByUser,
  SearchIdentityDocument,
  SearchIdentityDocumentByUser,
  ValidateMyProfile,
  searchProfileById
};
